import React from 'react';
import cx from 'classnames';
import { ProgressBar } from '../../components/base';
import Hero from '../../components/Hero';
import ArticleContentSwitch from '../../components/ArticleContentSwitch';
import ArticleNav from '../../components/ArticleNav';
import { Article as Meta } from '../../components/Meta';
import ExploreScrollButton from '../../components/ExploreScrollButton';
import AuthorBioBlock from '../../components/AuthorBioBlock';
import ArticleTagsBlock from '../../components/ArticleTagsBlock';
import ArticleEditorsNote from '../../components/ArticleEditorsNote';
import ArticleEndNote from '../../components/ArticleEndNote';
import ShareButton from '../../components/ShareButton';
import { ArticleRelatedRecipes } from './components';
import { definitely } from '../../utils/definitely';
import getAuthorNames from '../../utils/getAuthorNames';
import environmentIsProd from '../../utils/environmentIsProd';
import ArticleImgCarousel from '../../components/base/ArticleImgCarousel';
import { extractImagesFromArticle } from '../../utils/extractImagesFromArticle';

import { curlyQuotes } from '../../utils/text';
import {
  Article,
  ArticleBody,
  ArticleLink,
  ArticleHeroType,
} from '../../sharedTypes';
import { useUI } from '../../providers/UIProvider';
import { useArticleUiState } from './articleUiState';
import generateArticleUrl from '../../utils/generateArticleUrl';
import { useEffect, useState } from 'react';
import { useSiteSettings } from '../../providers/SiteSettingsProvider';
import CollectionEditorNote from '../../components/CollectionEditorNote';
import { shouldCollectionsEditorNoteFullWidth } from '../../utils/collections';
import ApiClient from '../../lib/ApiClient';
import Divider from '../../components/Divider';
import { useRouter } from 'next/router';

interface Props {
  article: Article;
}

const ArticleViewBase: React.FC<Props> = ({ article }) => {
  const [isTwoColHeroType, setIsTwoColHeroType] = useState(true);
  const [showCollectionEmbed, setShowCollectionEmbed] = useState(false);
  const [isCollectionBoxHidden, setIsCollectionBoxHidden] = useState(false);

  useEffect(() => {
    if (
      [
        ArticleHeroType.NO_IMAGE,
        ArticleHeroType.FULL_WIDTH_IMAGE_WITH_HALF_WIDTH_HEADER,
        ArticleHeroType.HALF_WIDTH_HEADER_WITH_IMAGE,
      ].includes(article.hero.heroType)
    ) {
      setIsTwoColHeroType(true);
    } else {
      setIsTwoColHeroType(false);
    }
  }, [article.hero.heroType]);

  const {
    carouselIsOpen,
    activeImageId,
    openCarouselOverlay,
    closeCarouselOverlay,
  } = useArticleUiState();

  const { sideNavIsOpen } = useUI();

  const { preview } = useSiteSettings();

  const router = useRouter();

  const onHashChangeStart = (url: string) => {
    const hash = url.indexOf('#') != -1 ? url.split('#')[1] : '';
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };

  useEffect(() => {
    router.events.on('hashChangeStart', onHashChangeStart);

    return () => {
      router.events.off('hashChangeStart', onHashChangeStart);
    };
  }, [router.events]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const articleSections = document.querySelectorAll('.InPageAnchor');
      let currentSectionId = '';

      for (let i = 0; i < articleSections.length; i++) {
        const section = articleSections[i] as HTMLElement;
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;
        if (scrollY >= sectionTop && scrollY < sectionTop + sectionHeight) {
          currentSectionId = section.getAttribute('id') || '';
          break;
        }
      }

      if (currentSectionId) {

        const newUrl = `${window.location.pathname}#${currentSectionId}`;
        window.history.pushState({ path: newUrl }, '', newUrl);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [relatedArticlesByCollection, setRelatedArticlesByCollection] =
    useState<ArticleLink[]>([]);

  useEffect(() => {
    const fetchRelatedArticles = async () => {
      setRelatedArticlesByCollection(
        await ApiClient.fetchArticlesByCollection(
          article?.collection?.slug ?? ''
        )
      );
    };
    fetchRelatedArticles();
  }, [article?.collection?.slug]);

  const HOST = environmentIsProd()
    ? `https://www.tabletmag.com`
    : `https://staging.tabletmag.com`;
  // @ts-ignore

  const articlePath = generateArticleUrl(article);
  const currentUrl = `${HOST}${articlePath}`;

  const { meta } = article;
  const { legacyRecipeMigration, relatedRecipes, relatedRecipesDisplay } = meta;
  const { markedComplete } = legacyRecipeMigration;

  const mapContents = (content: ArticleBody, index: number) => {
    if (content.type !== 'relatedStoriesByCollection') {
      return content;
    }

    return {
      ...content,
      articles: relatedArticlesByCollection,
    };
  };

  /* filter out legacy recipe blocks if marked complete */
  /* filter out `Continue reading:` blocks */
  const articleContents = (
    (markedComplete && Array.isArray(article.content.body)
      ? //@ts-ignore
        definitely(article.content.body).filter(
          (block) =>
            // @ts-ignore
            block._type !== 'legacyRecipeBlock' && block._type !== 'recipeBlock'
        )
      : // @ts-ignore
        definitely(article.content.body)
    ).filter((block: any) => {
      if (block._type === 'block') {
        return !block.children.some((child: any) =>
          child.text.includes('Continue reading:')
        );
      }
      return true;
    }) as unknown as ArticleBody[]
  ).map(mapContents);

  useEffect(() => {
    const showCollectionEmbed = articleContents.some(
      (content) =>
        content.type === 'collectionEmbed' && content.showCollection === true
    );

    const hideCollectionBox = articleContents.some(
      (content) =>
        content.type === 'collectionEmbed' && content.showCollection === false
    );

    setShowCollectionEmbed(showCollectionEmbed);
    setIsCollectionBoxHidden(hideCollectionBox);
  }, [articleContents]);

  const hasCollection =
    article.collection && Object.keys(article.collection).length > 0;

  useEffect(() => {
    if (!window.location.hash) return;
    // If a user's URL has an #anchor,
    // this timeout will scroll them to it
    const timeout = window.setTimeout(() => {
      window.location.href = `${window.location.href}`;
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div
      className={cx(
        'ArticleView content-height inner-content-max-width mxauto view content-padding-x pb3 sm:pb7 mxauto text-article-dropcaps',
        {
          'ArticleView--graphic-story':
            article.meta.articleType === 'graphic-story',
          'ArticleView--is-legacy': article.legacy.isLegacy,
        }
      )}
    >
      <Meta article={article} isPreview={!!preview} />
      <ProgressBar sideNavIsOpen={sideNavIsOpen} />
      <ArticleNav article={article} section={meta.section} />

      <article>
        <Hero article={article} />
        {article.notes.editorsNote.length !== 0 && (
          <>
            <ArticleEditorsNote
              heroType={article.hero.heroType}
              note={article.notes.editorsNote}
            />
            <Divider key="editors-note" variant="dotted-rule" />
          </>
        )}
        <div className="ArticleView__content sefaria-parse article-content-container mxauto text-article-dropcaps">
          {hasCollection && !isCollectionBoxHidden && !showCollectionEmbed && (
            <CollectionEditorNote
              isArticleEditorsNotePresent={
                article.notes.editorsNote.length !== 0
              }
              title={article.collection.title}
              slug={article.collection.slug}
              fullWidth={shouldCollectionsEditorNoteFullWidth(
                article.hero.heroType
              )}
              position={
                shouldCollectionsEditorNoteFullWidth(article.hero.heroType)
                  ? 'full-width'
                  : 'left'
              }
            />
          )}

          {Array.isArray(articleContents) ? (
            <ArticleContentSwitch
              article={article}
              // @ts-ignore
              contents={articleContents}
              isTwoColHeroType={isTwoColHeroType}
              enableDropCaps={true}
              className="ArticleView__content-switch bradford text-article-body-md font-300 mxauto"
              openCarouselOverlay={openCarouselOverlay}
              insertChildrenAt={4}
            >
              {relatedRecipes &&
              relatedRecipes.length &&
              relatedRecipesDisplay === 'aside' ? (
                <ArticleRelatedRecipes
                  displayAside
                  relatedRecipes={relatedRecipes}
                />
              ) : null}
            </ArticleContentSwitch>
          ) : null}

          {relatedRecipes &&
          relatedRecipes.length > 0 &&
          relatedRecipesDisplay !== 'aside' ? (
            <ArticleRelatedRecipes relatedRecipes={relatedRecipes} />
          ) : null}
          {article.notes.endNote.length !== 0 && (
            <ArticleEndNote note={article.notes.endNote} />
          )}
          <AuthorBioBlock authors={article.meta.authors} />
          <ArticleTagsBlock tags={article.meta.tags} />
        </div>
        {article.meta.articleType === 'graphic-story' && (
          <ArticleImgCarousel
            images={extractImagesFromArticle(article)}
            activeImageId={activeImageId}
            carouselIsOpen={carouselIsOpen}
            closeCarouselOverlay={closeCarouselOverlay}
          />
        )}
      </article>
      <ShareButton
        className="ArticleView__share"
        articleTitle={curlyQuotes(article.title)}
        articleBrief={`${curlyQuotes(article.title)}\n${curlyQuotes(
          article.meta.brief
        )}\nBy ${getAuthorNames(
          article.meta.authors
        )}\nRead Article: ${currentUrl}`}
      />
      <ExploreScrollButton theme="dark" />
    </div>
  );
};

export const ArticleView = ArticleViewBase;
