import { AuthorLink } from 'sharedTypes';
import commaAnd from 'utils/commaAnd';
import { definitely } from 'utils/definitely';

export const getRecipeAuthorNames = (
  authors: Pick<AuthorLink, 'firstName' | 'lastName'>[],
  recipeAuthors?: string[]
): string | null => {
  const filteredRecipeAuthors = definitely(recipeAuthors).filter(
    (a) => a.length > 1
  );
  const authorNames = filteredRecipeAuthors.length
    ? filteredRecipeAuthors
    : definitely(authors).map(({ firstName, lastName }) =>
        [firstName, lastName].join(' ')
      );
  if (authorNames.length === 0) return null;
  const text = [commaAnd(authorNames)].join(' ');
  return text;
};
