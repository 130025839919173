import React from 'react';
import cx from 'classnames';

import generateTagUrl from 'utils/generateTagUrl';
import { Button } from 'components/base';
import Language from 'constants/Language';
import { TagLink } from 'sharedTypes';

interface Props {
  className?: string;
  tags: TagLink[];
}

const ArticleTagBlock: React.FC<Props> = ({ className, tags }) => {
  return (
    <div
      className={cx(
        'ArticleTagsBlock relative w100 mt6 col-12 lg:col-10 xl-wide:col-8 mxauto',
        className
      )}
    >
      <div className="ArticleTagsBlock__border border-bottom-black w100" />
      {tags.map((tag) => {
        return (
          <Button
            key={tag.slug}
            ariaLabel={Language.t('Widgets.TagWidget.moreAriaLabel')}
            to={generateTagUrl(tag)}
            containerClassName="mr1_5"
            className="graebenbach font-400 color-black"
          >
            <span className="ArticleTagsBlock__tag uppercase">
              {Language.t('Widgets.TagWidget.tagTitle', { title: tag.title })}
            </span>
          </Button>
        );
      })}
    </div>
  );
};

export default ArticleTagBlock;
