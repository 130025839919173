import React, { Component, useEffect, useRef, useState } from 'react';
import { BlockContent } from 'components/BlockContent';
import cx from 'classnames';
import withBreakpoints, {
  InjectedProps as WithBreakpointsProps,
} from 'lib/withBreakpoints';
import { Button } from 'components/base';
import { ArticleHeroType, PortableText } from 'sharedTypes';
import { Sub, Sup, Code } from 'components/serializers';
import Language from 'constants/Language';
import lodash from 'lodash';

interface Props {
  children?: React.ReactNode;
}

interface Props extends WithBreakpointsProps {
  className?: string;
  note: PortableText;
  heroType: ArticleHeroType;
}

const ArticleEditorsNote: React.FC<Props> = ({ className, note, heroType }) => {
  const [isClamped, setIsClamped] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const heroIsFullWidth =
    heroType === ArticleHeroType.FULL_WIDTH_HEADER_WITH_IMAGE ||
    heroType === ArticleHeroType.NO_IMAGE_CENTERED_TEXT;

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const hasClamping = (el: HTMLDivElement) => {
    const { clientHeight, scrollHeight } = el;
    return clientHeight !== scrollHeight;
  };

  useEffect(() => {
    const checkButtonAvailability = () => {
      if (containerRef.current) {
        const numLines = containerRef.current.scrollHeight / 20;
        if (heroIsFullWidth) {
          setIsClamped(numLines > 4);
        } else {
          setIsClamped(numLines > 6);
        }
        const hadClampClass = containerRef.current.classList.contains('clamp');
        if (!hadClampClass) containerRef.current.classList.add('clamp');
        setShowButton(hasClamping(containerRef.current));
        if (!hadClampClass) containerRef.current.classList.remove('clamp');
      }
    };

    checkButtonAvailability();

    const debouncedCheck = lodash.debounce(checkButtonAvailability, 50);

    window.addEventListener('resize', debouncedCheck);

    return () => {
      window.removeEventListener('resize', debouncedCheck);
    };
  }, [containerRef, heroIsFullWidth]);

  return (
    <div
      className={cx(
        'ArticleEditorsNote col-12 lg:col-10 xl-wide:col-8 mxauto bradford text-article-body-md italic font-300',
        className,
        {
          'ArticleEditorsNote--half-width': !heroIsFullWidth,
        }
      )}
    >
      <div
        ref={containerRef}
        className={cx(
          'ArticleEditorsNote__content BlockContent',
          { clamp: isClamped && !isExpanded },
          className
        )}
      >
        <div
          className={cx('ArticleEditorsNote__preview transition t0 l0 w100')}
        >
          <span className="font-600">Editor’s note: </span>
          <BlockContent
            serializers={{
              marks: {
                sup: Sup,
                sub: Sub,
                code: Code,
              },
              block: {
                normal: ({ children }: any) => <span>{children}</span>,
              },
            }}
            blocks={note}
          />
          &nbsp;
          {showButton && isExpanded && (
            <Button
              ariaLabel={Language.t('Global.showLess')}
              onClick={handleClick}
              className={cx(
                'ArticleEditorsNote__read-more bradford text-article-body-md italic font-300'
              )}
            >
              <span className="underline">{Language.t('Global.showLess')}</span>
            </Button>
          )}
        </div>
      </div>
      {showButton && !isExpanded && (
        <Button
          ariaLabel={Language.t('Global.showMore')}
          onClick={handleClick}
          className={cx(
            'ArticleEditorsNote__read-more bradford text-article-body-md italic font-300'
          )}
        >
          <span className="underline">{Language.t('Global.showMore')}</span>
        </Button>
      )}
    </div>
  );
};

export default withBreakpoints<Props>(ArticleEditorsNote);
