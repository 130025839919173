import React from 'react';
import { Link } from './Link';
import { ImageHotspot } from 'sharedTypes';
import cx from 'classnames';

interface HotspotProps {
  hotspot: ImageHotspot;
  className?: string;
}

export const Hotspot: React.FC<HotspotProps> = ({ hotspot, className }) => {
  const { hotspotText, hotspotLink, x, y, useDarkModeColorScheme } = hotspot;

  const textColor = useDarkModeColorScheme ? '#e6e6e6' : '#f7f6f2';
  const textBackgroundColor = useDarkModeColorScheme ? '#222222' : '#e73f22';

  const hotspotLinkStyles = {
    color: textColor,
    backgroundColor: textBackgroundColor,
    borderRadius: '40px',
    padding: '4px 14px',
  };

  return (
    <div
      className={cx(className, 'Hotspot')}
      style={{
        position: 'absolute',
        top: `${(y / 100) * 100}%`,
        left: `${(x / 100) * 100}%`,
      }}
    >
      <div className="Hotspot__content">
        <Link
          to={hotspotLink}
          className="text-article-body-md graebenbach text-decoration-none items-center justify-center"
          style={hotspotLinkStyles}
        >
          <span>{hotspotText}</span>
        </Link>
      </div>
    </div>
  );
};
