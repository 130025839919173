import React, { Component } from 'react';
import cx from 'classnames';
import { format } from 'date-fns';
import get from 'lodash/get';

import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';
import { Img, Button } from 'components/base';
import Language from 'constants/Language';
import generateAuthorUrl from 'utils/generateAuthorUrl';
import generateSectionUrl from 'utils/generateSectionUrl';
import { onWindowResize } from 'utils/onWindowResize';
import { curlyQuotes } from 'utils/text';

import { Article, ArticleHeroType } from 'sharedTypes';
import FictionTag from './FictionTag';
import { BlockContent } from './BlockContent';
import { Hotspot } from './Hotspot';

interface Props {
  article: Article;
}

interface State {
  articleNavHeight: number;
}

class Hero extends Component<Props, State> {
  state = {
    articleNavHeight: 0,
  };
  removeResizeListener = () => {};

  getNavHeight = () => {
    const articleNavHeight = get(
      document.getElementsByClassName('Nav'),
      '[0].clientHeight',
      0
    );
    if (this.state.articleNavHeight !== articleNavHeight) {
      this.setState({ articleNavHeight });
    }
  };

  componentDidMount() {
    this.getNavHeight();
    this.removeResizeListener = onWindowResize(this.getNavHeight);
  }

  componentWillUnmount() {
    this.removeResizeListener();
  }

  render() {
    const { article } = this.props;
    const { hero, meta } = article;
    const authorsLength = meta.authors.length;
    const heroType = hero.heroType;
    const isHighDefImage = meta.isHighDefImage;

    return (
      <div
        style={{ paddingTop: `${this.state.articleNavHeight}px` }}
        className={cx('Hero', {
          'Hero--centered-text':
            heroType === ArticleHeroType.NO_IMAGE_CENTERED_TEXT,
        })}
      >
        {heroType ===
          ArticleHeroType.FULL_WIDTH_IMAGE_WITH_HALF_WIDTH_HEADER && (
          <div className="Hero__image Hero--full-image inner-content-max-width none lg:mt1_5 lg:block mxauto">
            <div className="relative w100">
              <Img
                className="w100 radius-sm mb_625"
                src={sanityImgUtil(
                  hero.heroImage,
                  1300,
                  {},
                  70,
                  isHighDefImage
                )}
                srcSet={
                  isHighDefImage
                    ? ''
                    : sanityImgSrcSetUtil(hero.heroImage, 768, 950, 1300)
                }
                sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 950px, 1300px"
                alt={hero.heroImage.credit || hero.heroImage.alt || ''}
                dimensions={
                  hero.heroImage.metadata && hero.heroImage.metadata.dimensions
                }
                crop={hero.heroImage.crop}
              />
              {hero.imageHotspots &&
                hero.imageHotspots.map((hotspot, index) => (
                  <Hotspot
                    key={index}
                    hotspot={hotspot}
                    className="none sm:flex"
                  />
                ))}
            </div>
            {hero.heroImage.caption && (
              <span className="Hero__caption mr_25 color-gray-darkest text-article-details-xs font-400 graebenbach">
                {curlyQuotes(hero.heroImage.caption)}
              </span>
            )}
            {hero.heroImage.rteCredit ? (
              <span className="Hero__rte-credit Hero__credit ImageCreditLink inline-block color-gray text-article-details-xxs font-400 uppercase graebenbach letter-spacing-md">
                <BlockContent blocks={hero.heroImage.rteCredit} />
              </span>
            ) : hero.heroImage.credit ? (
              <span className="Hero__credit color-gray text-article-details-xxs font-400 uppercase graebenbach letter-spacing-md">
                {curlyQuotes(hero.heroImage.credit)}
              </span>
            ) : (
              ''
            )}
          </div>
        )}

        <div className="article-content-container col-12 xl-wide:col-10 mxauto">
          <div
            className={cx('lg:mr1_5 lg:pr1_5', {
              'Hero__header-container--half-width border-bottom-black flex flex-col':
                heroType ===
                  ArticleHeroType.FULL_WIDTH_IMAGE_WITH_HALF_WIDTH_HEADER ||
                heroType === ArticleHeroType.HALF_WIDTH_HEADER_WITH_IMAGE ||
                heroType === ArticleHeroType.NO_IMAGE,
              'Hero__header-container--full-width border-bottom-black lg:pt0 lg:flex':
                heroType === ArticleHeroType.FULL_WIDTH_HEADER_WITH_IMAGE,
              'Hero__header-container--centered-text border-bottom-black lg:pt0':
                heroType === ArticleHeroType.NO_IMAGE_CENTERED_TEXT,
              'lg:flex':
                heroType === ArticleHeroType.NO_IMAGE_CENTERED_TEXT &&
                !article.column,
              'items-start lg:items-center':
                article.column &&
                heroType === ArticleHeroType.FULL_WIDTH_HEADER_WITH_IMAGE,
              'lg:pb1_25':
                heroType === ArticleHeroType.HALF_WIDTH_HEADER_WITH_IMAGE ||
                heroType === ArticleHeroType.FULL_WIDTH_HEADER_WITH_IMAGE,
            })}
          >
            <div
              className={cx(
                'Hero__info-container w100 flex flex-col items-center hauto',
                {
                  'justify-between':
                    heroType !== ArticleHeroType.FULL_WIDTH_HEADER_WITH_IMAGE,
                  'Hero__info-container--vertical justify-center lg:col-6':
                    heroType === ArticleHeroType.FULL_WIDTH_HEADER_WITH_IMAGE,
                }
              )}
            >
              <div className="flex flex-col items-center">
                <div
                  className={cx('Hero__section-icon-container', {
                    'Hero__section-icon-container--vertical':
                      heroType === ArticleHeroType.FULL_WIDTH_HEADER_WITH_IMAGE,
                  })}
                >
                  {meta.isFiction ? (
                    <FictionTag />
                  ) : (
                    meta.section.images.dark.src && (
                      <Button
                        ariaLabel={Language.t('Global.sectionButtonAriaLabel', {
                          sectionTitle: meta.section.title,
                        })}
                        className="bg-color-transparent linked-border-bottom-red"
                        to={generateSectionUrl(meta.section.slug)}
                      >
                        <Img
                          alt={Language.t('Global.sectionButtonAriaLabel', {
                            sectionTitle: meta.section.title,
                          })}
                          className="Hero__section-icon"
                          src={sanityImgUtil(meta.section.images.dark, 200)}
                          dimensions={
                            meta.section.images.dark.metadata?.dimensions
                          }
                        />
                      </Button>
                    )
                  )}
                </div>
                <h1
                  className={cx(
                    'Hero__headline itc-cushing text-center font-300',
                    {
                      'Hero__headline--vertical':
                        heroType ===
                        ArticleHeroType.FULL_WIDTH_HEADER_WITH_IMAGE,
                    }
                  )}
                >
                  {curlyQuotes(article.title)}
                </h1>
                <h2
                  className={cx(
                    'Hero__dek color-gray-darker graebenbach text-center font-400',
                    {
                      'Hero__dek--vertical':
                        heroType ===
                        ArticleHeroType.FULL_WIDTH_HEADER_WITH_IMAGE,
                    }
                  )}
                >
                  {curlyQuotes(hero.dek)}
                </h2>
                <span
                  className={cx(
                    'Hero__author itc-cushing font-800 uppercase flex justify-center items-start flex-wrap',
                    {
                      'Hero__author--vertical':
                        heroType ===
                        ArticleHeroType.FULL_WIDTH_HEADER_WITH_IMAGE,
                    }
                  )}
                >
                  <span className="Hero__author-text pr_25">
                    {Language.t('Global.byline')}
                  </span>
                  {meta.authors &&
                    meta.authors.map((author, i) => {
                      const name = `${author.firstName} ${author.lastName}`;
                      return (
                        <React.Fragment key={author.slug}>
                          <Button
                            key={name}
                            ariaLabel={Language.t(
                              'Global.authorButtonAriaLabel',
                              {
                                authorName: name,
                              }
                            )}
                            className="flex items-start"
                            to={generateAuthorUrl(author)}
                          >
                            <span
                              className={cx(
                                'Hero__author-text linked-border-bottom-red color-black',
                                {
                                  mr_25: !(
                                    i !== authorsLength - 1 &&
                                    authorsLength !== 2
                                  ),
                                }
                              )}
                            >
                              {name}
                            </span>
                            {i !== authorsLength - 1 && authorsLength !== 2 && (
                              <span className="Hero__author-text mr_25">
                                {Language.t('Global.comma')}
                              </span>
                            )}
                          </Button>
                          {i !== authorsLength - 1 &&
                            i === authorsLength - 2 && (
                              <span className="Hero__author-text mr_25">
                                {Language.t('Global.and')}
                              </span>
                            )}
                        </React.Fragment>
                      );
                    })}
                </span>
              </div>
              {meta.date && (
                <div
                  className={cx('Hero__date', {
                    'Hero__date--vertical':
                      heroType === ArticleHeroType.FULL_WIDTH_HEADER_WITH_IMAGE,
                  })}
                >
                  <span className="color-gray-darker graebenbach font-400 uppercase letter-spacing-md">
                    {format(new Date(meta.date), 'MMMM dd, yyyy')}
                  </span>
                </div>
              )}

              {article.column &&
                Object.keys(article.column).length > 0 &&
                heroType === ArticleHeroType.FULL_WIDTH_HEADER_WITH_IMAGE && (
                  <div
                    className={
                      'ColumnsCarousel__article__container ColumnsCarousel__article__vertical'
                    }
                  >
                    <div
                      className={'ColumnsCarousel__article__title graebenbach'}
                    >
                      {article.column.title}
                    </div>
                    <div className={'ColumnsCarousel__article__description'}>
                      {article.column.description}{' '}
                      <Button
                        ariaLabel={Language.t('Global.seeFullCollection')}
                        to={'/columns/' + article.column.slug}
                        wrap={true}
                      >
                        <u>
                          {Language.t('Global.allColumnStories', {
                            title: article.column.title,
                          })}{' '}
                          &rarr;&#xFE0E;
                        </u>
                      </Button>
                    </div>
                  </div>
                )}
            </div>

            {heroType === ArticleHeroType.HALF_WIDTH_HEADER_WITH_IMAGE && (
              <>
                <div className="relative w100 mt1_25 none lg:block">
                  <Img
                    className="w100 mb_625"
                    alt={hero.heroImage.credit || hero.heroImage.alt || ''}
                    sizes="(maxWidth: 768px) 384px, (maxWidth: 1080px) 540px, 1250px"
                    src={sanityImgUtil(
                      hero.heroImage,
                      1250,
                      {},
                      70,
                      isHighDefImage
                    )}
                    srcSet={
                      isHighDefImage
                        ? ''
                        : sanityImgSrcSetUtil(hero.heroImage, 384, 540, 1250)
                    }
                    dimensions={
                      hero.heroImage.metadata &&
                      hero.heroImage.metadata.dimensions
                    }
                    crop={hero.heroImage.crop}
                  />
                  {hero.imageHotspots &&
                    hero.imageHotspots.map((hotspot, index) => (
                      <Hotspot
                        key={index}
                        hotspot={hotspot}
                        className="none sm:flex"
                      />
                    ))}
                </div>
                <div className="w100 none lg:block">
                  {hero.heroImage.caption && (
                    <span className="Hero__caption mr_25 color-gray-darkest text-article-details-sm font-400 graebenbach">
                      {hero.heroImage.caption}
                    </span>
                  )}
                  {hero.heroImage.rteCredit ? (
                    <span className="Hero__rte-credit Hero__credit ImageCreditLink inline-block color-gray text-article-details-xxs font-400 uppercase graebenbach letter-spacing-md">
                      <BlockContent blocks={hero.heroImage.rteCredit} />
                    </span>
                  ) : hero.heroImage.credit ? (
                    <span className="Hero__credit color-gray text-article-details-xxs font-400 uppercase graebenbach letter-spacing-md">
                      {curlyQuotes(hero.heroImage.credit)}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </>
            )}

            {heroType === ArticleHeroType.FULL_WIDTH_HEADER_WITH_IMAGE && (
              <div className="w100 none lg:block lg:col-6 lg:pl1_5">
                <div className="relative w100 mb_625">
                  <Img
                    className="w100"
                    alt={hero.heroImage.credit || hero.heroImage.alt || ''}
                    sizes="(maxWidth: 768px) 384px, (maxWidth: 1080px) 540px, 1250px"
                    src={sanityImgUtil(
                      hero.heroImage,
                      1250,
                      {},
                      70,
                      isHighDefImage
                    )}
                    srcSet={
                      isHighDefImage
                        ? ''
                        : sanityImgSrcSetUtil(hero.heroImage, 384, 540, 1250)
                    }
                    dimensions={
                      hero.heroImage.metadata &&
                      hero.heroImage.metadata.dimensions
                    }
                    crop={hero.heroImage.crop}
                  />
                  {hero.imageHotspots &&
                    hero.imageHotspots.map((hotspot, index) => (
                      <Hotspot
                        key={index}
                        hotspot={hotspot}
                        className="none sm:flex"
                      />
                    ))}
                </div>
                <div className="w100 none lg:block">
                  {hero.heroImage.caption && (
                    <span className="Hero__caption mr_25 color-gray-darkest text-article-details-xs font-400 graebenbach">
                      {hero.heroImage.caption}
                    </span>
                  )}
                  {hero.heroImage.rteCredit ? (
                    <span className="Hero__rte-credit Hero__credit ImageCreditLink inline-block color-gray text-article-details-xxs font-400 uppercase graebenbach letter-spacing-md">
                      <BlockContent blocks={hero.heroImage.rteCredit} />
                    </span>
                  ) : hero.heroImage.credit ? (
                    <span className="Hero__credit color-gray text-article-details-xxs font-400 uppercase graebenbach letter-spacing-md">
                      {curlyQuotes(hero.heroImage.credit)}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            )}

            {article.column &&
              Object.keys(article.column).length > 0 &&
              !(heroType === ArticleHeroType.FULL_WIDTH_HEADER_WITH_IMAGE) && (
                <div className={'ColumnsCarousel__article__container'}>
                  <div
                    className={'ColumnsCarousel__article__title graebenbach'}
                  >
                    {article.column.title}
                  </div>
                  <div className={'ColumnsCarousel__article__description'}>
                    {article.column.description}{' '}
                    <Button
                      ariaLabel={Language.t('Global.seeFullCollection')}
                      to={'/columns/' + article.column.slug}
                      wrap={true}
                    >
                      <u>
                        {Language.t('Global.allColumnStories', {
                          title: article.column.title,
                        })}{' '}
                        &rarr;&#xFE0E;
                      </u>
                    </Button>
                  </div>
                </div>
              )}
          </div>
          {heroType !== ArticleHeroType.NO_IMAGE &&
            heroType !== ArticleHeroType.NO_IMAGE_CENTERED_TEXT && (
              <div className="Hero__image border-bottom-black lg:none mxauto mt_5 pb2_25 pt_75">
                <div className="relative w100">
                  <Img
                    className="w100 radius-xs mb_625"
                    alt={hero.heroImage.credit || hero.heroImage.alt || ''}
                    src={sanityImgUtil(
                      hero.heroImage,
                      1300,
                      {},
                      70,
                      isHighDefImage
                    )}
                    srcSet={
                      isHighDefImage
                        ? ''
                        : sanityImgSrcSetUtil(hero.heroImage, 768, 950, 1300)
                    }
                    sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 950px, 1300px"
                    dimensions={
                      hero.heroImage.metadata &&
                      hero.heroImage.metadata.dimensions
                    }
                    crop={hero.heroImage.crop}
                  />
                  {hero.imageHotspots &&
                    hero.imageHotspots.map((hotspot, index) => (
                      <Hotspot
                        key={index}
                        hotspot={hotspot}
                        className="none sm:flex"
                      />
                    ))}
                </div>
                {hero.heroImage.caption && (
                  <span className="Hero__caption mr_25 color-gray-darkest text-article-details-sm font-300 graebenbach">
                    {hero.heroImage.caption}
                  </span>
                )}
                {hero.heroImage.rteCredit ? (
                  <span className="Hero__rte-credit Hero__credit ImageCreditLink inline-block color-gray text-article-details-xxs font-400 uppercase graebenbach letter-spacing-md">
                    <BlockContent blocks={hero.heroImage.rteCredit} />
                  </span>
                ) : hero.heroImage.credit ? (
                  <span className="Hero__credit color-gray text-article-details-xxs font-400 uppercase graebenbach letter-spacing-md">
                    {curlyQuotes(hero.heroImage.credit)}
                  </span>
                ) : (
                  ''
                )}
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default Hero;
