import React, { useEffect, useState } from 'react';
import { Link } from 'components/Link';
import cx from 'classnames';
import get from 'lodash/get';
import generateSectionUrl from 'utils/generateSectionUrl';
import generateArticleUrl from 'utils/generateArticleUrl';
import getAuthorNames from 'utils/getAuthorNames';
import sanityImgUtil from 'utils/sanityImgUtil';
import environmentIsProd from 'utils/environmentIsProd';
import truncate from 'utils/truncate';
import { Img, Button } from 'components/base';
import ShareButton from 'components/ShareButton';
import Nav from 'components/Nav';
import { RouteMap } from 'constants/RouteMap';
import Language from 'constants/Language';
import { Article, SectionLink } from 'sharedTypes';
import { Theme } from 'types';
import { curlyQuotes } from 'utils/text';
import { useScrollPosition, Direction } from 'utils/useScrollPosition';
import { useUI } from 'providers/UIProvider';
import { TabletLogo } from './icons';
import { useIsFiction } from 'src/hooks/useFiction';

interface ArticleNavProps {
  article: Article;
  section: SectionLink;
}

export const ArticleNav: React.FC<ArticleNavProps> = (props) => {
  const { article, section } = props;

  const { theme } = useUI();
  const isFiction = useIsFiction();
  const { position, direction } = useScrollPosition();
  const [showArticleInfoNav, setShowArticleInfoNav] = useState<boolean>(false);
  const logoColor = isFiction ? 'blue' : 'dark';

  const HOST = environmentIsProd()
    ? `https://www.tabletmag.com`
    : `https://staging.tabletmag.com`;
  // @ts-ignore
  const currentUrl = `${HOST}${generateArticleUrl(article)}`;

  const handlePrintButtonClick = () => {
    window.print();
  };

  useEffect(() => {
    if (position === 0) {
      setShowArticleInfoNav(false);
    } else {
      if (direction === Direction.Up) {
        setShowArticleInfoNav(false);
      } else {
        setShowArticleInfoNav(true);
      }
    }
  }, [position, direction]);
  return (
    <Nav className="ArticleNav" hideNav={!showArticleInfoNav}>
      <Link
        to={RouteMap.HOME.path}
        className={cx('ArticleNav__logo absolute', {
          'ArticleNav__logo--active': !showArticleInfoNav,
        })}
      >
        <TabletLogo
          className="Nav__logo-icon"
          color={isFiction ? 'fiction-blue' : 'black'}
        />
      </Link>
      <div
        className={cx(
          'ArticleNav__article-info my_75 pt_5 lg:pt0 h100 w100 flex items-center justify-between events-none',
          {
            'ArticleNav__article-info--active': showArticleInfoNav,
          }
        )}
      >
        <div
          className={cx('ArticleNav__section col-2 none lg:block', {
            'ArticleNav__section--active': showArticleInfoNav,
          })}
        >
          <Button
            ariaLabel={Language.t('Global.sectionButtonAriaLabel', {
              sectionTitle: section.title,
            })}
            to={generateSectionUrl(section.slug)}
            className="bg-color-transparent"
          >
            <Img
              className="ArticleNav__section-logo"
              alt={Language.t('Global.sectionButtonAriaLabel', {
                sectionTitle: section.title,
              })}
              src={sanityImgUtil(article.meta.section.images[logoColor], 200)}
              dimensions={
                article.meta.section.images[logoColor].metadata?.dimensions
              }
            />
          </Button>
        </div>
        <div className="col-8 lg:px1">
          <p className="ArticleNav__title w100 itc-cushing nowrap lg:text-center font-600">
            {curlyQuotes(truncate(article.title, 100))}
          </p>
        </div>
        <div className="ArticleNav__share-button-container z1 relative col-2 flex justify-end relative">
          <ShareButton
            className={cx('ArticleNav__share-button', {
              'ShareButton--events-none': !showArticleInfoNav,
            })}
            articleTitle={article.title}
            hidePrint={true}
            articleBrief={`${article.title}\n${
              article.meta.brief
            }\nBy ${getAuthorNames(
              article.meta.authors
            )}\nRead Article: ${currentUrl}`}
          />
          {/* yanked from ShareButton.tsx */}
          <Button
            ariaLabel={Language.t('ShareButton.printButtonAriaLabel')}
            className={cx(
              'ShareButton__text ShareButton__text--print graebenbach font-400 bg-color-transparent',
              {
                'color-black': theme === Theme.Beige || theme === Theme.Default,
                'color-white-dark': theme === Theme.Black,
              }
            )}
            onClick={handlePrintButtonClick}
          >
            Print
            <div
              className={cx(
                'ShareButton__arrow-icon transition-short inline-block ml_25 button-move-arrow graebenbach font-400',
                {
                  'color-black': theme !== Theme.Black,
                  'color-off-white': theme === Theme.Black,
                }
              )}
            >
              &rarr;&#xFE0E;
            </div>
          </Button>
        </div>
      </div>
    </Nav>
  );
};

export default ArticleNav;
