import { compareAsc } from 'date-fns';
import ApiClient from 'lib/ApiClient';
import { getPreviewParam, withStaticBaseData } from 'utils/next';
import { Article } from 'sharedTypes';
import { ArticleView } from '../../../../views/ArticleView';
import { GetStaticPaths } from 'next';

interface ArticleProps {
  article: Article;
}

const ArticlePage: React.FC<ArticleProps> = ({ article }) => {
  return <ArticleView article={article} />;
};

type Params = {
  slug: string;
  sectionSlug: string;
};

export const getStaticProps = withStaticBaseData<ArticleProps, Params>(
  async (ctx) => {
    const slug = ctx?.params?.slug;
    const sectionSlugFromURL = ctx?.params?.sectionSlug;

    if (!slug) {
      throw new Error('No article slug was provided');
    }
    const previewId = getPreviewParam(ctx);
    const article = await ApiClient.fetchArticle(slug, previewId);

    if (!article || article.section.slug !== sectionSlugFromURL) {
      return { notFound: true };
    }

    const notYetPublished =
      article.meta.releaseDate &&
      compareAsc(new Date(article.meta.releaseDate), new Date()) > 0 &&
      !previewId;

    return {
      props: { article },
      notFound: notYetPublished,
    };
  }
);

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [],
    fallback: 'blocking',
  };
};

export default ArticlePage;
