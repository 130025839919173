import cx from 'classnames';
import { Link } from 'components/Link';
import { Img } from 'components/base';
import * as FeatureFlags from 'utils/featureFlags';
import { RecipeArticleLink } from 'sharedTypes';
import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';
import generateRecipeUrl from 'utils/generateRecipeUrl';
import { TeaserByLine } from 'components/Recipe/RecipeTeaser';
import { curlyQuotes } from 'utils/text';

interface ArticleRelatedRecipeProps {
  recipe: RecipeArticleLink;
  displayAside?: boolean;
}

const ArticleRelatedRecipe: React.FC<ArticleRelatedRecipeProps> = ({
  recipe,
  displayAside,
}) => {
  const { title, featuredImage, heroImage, authors, recipeAuthors } = recipe;
  const href = generateRecipeUrl(recipe);
  const image = featuredImage || heroImage;

  const dimensions = displayAside
    ? { width: 420, height: 276 }
    : { width: 300, height: 209 };

  return (
    <div
      className={cx(
        'ArticleRelatedRecipe',
        displayAside ? 'ArticleRelatedRecipe--aside' : undefined
      )}
    >
      {image && image.src ? (
        <div className="ArticleRelatedRecipe__image relative radius-xs overflow-hidden">
          <Link to={href}>
            <Img
              alt={image.alt || title}
              src={sanityImgUtil(image, 768)}
              srcSet={sanityImgSrcSetUtil(image, 768, 768)}
              sizes="(min-width: 900px) 768px, 768px;"
              dimensions={dimensions}
              crop={image.crop}
            />
          </Link>
        </div>
      ) : null}
      <div className="ArticleRelatedRecipe__body">
        <h4 className="ArticleRelatedRecipe__title graebenbach text-article-header-md font-300">
          {curlyQuotes(title)}
        </h4>
        <TeaserByLine authors={authors} recipeAuthors={recipeAuthors} />
        <p className="graebenbach ArticleRelatedRecipe__link">
          <Link to={href}>See the recipe &rarr;&#xFE0E;</Link>
        </p>
      </div>
    </div>
  );
};

interface ArticleRelatedRecipesProps {
  relatedRecipes: RecipeArticleLink[];
  displayAside?: boolean;
}

export const ArticleRelatedRecipes: React.FC<ArticleRelatedRecipesProps> = ({
  relatedRecipes,
  displayAside,
}) => {
  if (relatedRecipes.length === 0) return null;

  if (!FeatureFlags.isEnabled(FeatureFlags.Flags.RECIPE_ARTICLES)) {
    return null;
  }

  const isMultipleRecipes = relatedRecipes.length > 1;
  const heading = isMultipleRecipes ? 'The Recipes' : 'The Recipe';
  return (
    <div
      className={cx(
        'ArticleRelatedRecipes',
        displayAside
          ? 'ArticleRelatedRecipes--aside'
          : 'col-12 lg:col-10 xl-wide:col-8 mxauto',
        isMultipleRecipes
          ? 'ArticleRelatedRecipes--multiple'
          : 'ArticleRelatedRecipes--single'
      )}
    >
      <h3 className="ArticleRelatedRecipes__title itc-cushing uppercase font-700">
        {heading}
      </h3>
      <hr />
      {relatedRecipes.map((recipe) => (
        <ArticleRelatedRecipe
          displayAside={displayAside}
          key={recipe._id}
          recipe={recipe}
        />
      ))}
    </div>
  );
};
