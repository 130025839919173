import { useReducer } from 'react';

enum ActionType {
  SetCarousel = 'SET_CAROUSEL',
  SetActiveImage = 'SET_ACTIVE_IMAGE',
}

interface SetCarouselAction {
  type: ActionType.SetCarousel;
  activeImageId: string | null;
  carouselIsOpen: boolean;
}

interface SetActiveImageAction {
  type: ActionType.SetActiveImage;
  activeImageId: string;
}

type Action = SetCarouselAction | SetActiveImageAction;

interface State {
  carouselIsOpen: boolean;
  activeImageId: string | null;
}

const initialState: State = {
  carouselIsOpen: false,
  activeImageId: null,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SetCarousel:
      return {
        ...state,
        carouselIsOpen: action.carouselIsOpen,
        activeImageId: action.activeImageId,
      };
    case ActionType.SetActiveImage:
      return {
        ...state,
        activeImageId: action.activeImageId,
      };

    default:
      // @ts-expect-error
      throw new Error(`action "${action.type}" is not a valid action`);
  }
};

export const useArticleUiState = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const openCarouselOverlay = (activeImageId: string) =>
    dispatch({
      type: ActionType.SetCarousel,
      carouselIsOpen: true,
      activeImageId,
    });
  const closeCarouselOverlay = () =>
    dispatch({
      type: ActionType.SetCarousel,
      carouselIsOpen: false,
      activeImageId: null,
    });
  const setActiveImage = (activeImageId: string) =>
    dispatch({ type: ActionType.SetActiveImage, activeImageId });

  return {
    ...state,
    openCarouselOverlay,
    closeCarouselOverlay,
    setActiveImage,
  };
};
