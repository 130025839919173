import memoize from 'lodash/memoize';
import get from 'lodash/get';
import flatten from 'lodash/flatten';

import { Article, Image } from 'sharedTypes';

export const extractHeroImageFromArticle = function (
  article: Article
): Image | null {
  if (!!article.hero.heroImage.src) return article.hero.heroImage;

  return null;
};

export const extractImagesFromBlocks = function (article: Article): Image[] {
  let images: Image[] = [];

  if (Array.isArray(article.content.body)) {
    try {
      const imagesFromKnownBlocks = flatten(
        article.content.body.map((block) => {
          switch (block.type) {
            case 'insetImage':
              return get(block, 'image');
            case 'insetImageSlideshow':
              return get(block, 'slideshowImages');
            default:
              return null;
          }
        })
      );

      images = imagesFromKnownBlocks.filter(Boolean) as Image[];
    } catch (e) {
      console.error(
        'An error occured while extracting images from the article: ',
        e
      );
    }
  }

  return images;
};

export const extractImagesFromArticle = function (article: Article): Image[] {
  const heroImage = extractHeroImageFromArticle(article);
  const imagesInBlocks = extractImagesFromBlocks(article);

  return heroImage ? [heroImage, ...imagesInBlocks] : imagesInBlocks;
};

export default memoize(extractImagesFromArticle);
