import React, { Component } from 'react';
import { BlockContent } from 'components/BlockContent';
import cx from 'classnames';

import { Sub, Sup, Code } from 'components/serializers';

import { PortableText } from 'sharedTypes';

interface Props {
  className?: string;
  note: PortableText;
}

class ArticleEndNote extends Component<Props> {
  render() {
    const { className, note } = this.props;

    return (
      <div
        className={cx(
          'ArticleEndNote BlockContent col-12 lg:col-10 xl-wide:col-8 mxauto bradford text-article-body-md italic font-300',
          className,
          {}
        )}
      >
        <BlockContent
          blocks={note}
          serializers={{
            marks: {
              sup: Sup,
              sub: Sub,
              code: Code,
            },
          }}
        />
      </div>
    );
  }
}

export default ArticleEndNote;
