import { Link } from 'components/Link';

import { AuthorLink, RecipeArticleLink, RelatedRecipeMeta } from 'sharedTypes';
import { curlyQuotes } from 'utils/text';
import { Img } from 'components/base';
import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';
import generateRecipeUrl from 'utils/generateRecipeUrl';
import { getRecipeAuthorNames } from 'utils/recipes';
import capitalize from 'utils/capitalize';
import Language from 'constants/Language';

interface TeaserByLineProps {
  authors?: AuthorLink[];
  recipeAuthors?: string[];
}

export const TeaserByLine: React.FC<TeaserByLineProps> = ({
  authors,
  recipeAuthors,
}) => {
  const authorNames = getRecipeAuthorNames(authors || [], recipeAuthors);
  return (
    <h5 className="Teaser__byline graebenbach font-200 uppercase text-section-details-xs">
      {capitalize(Language.t('Global.byline'))} {authorNames}
    </h5>
  );
};

interface ExtendedRecipeArticleLink extends RecipeArticleLink {
  meta?: RelatedRecipeMeta;
}

interface RecipeTeaserProps {
  recipe: ExtendedRecipeArticleLink;
}

export const RecipeTeaser: React.FC<RecipeTeaserProps> = ({ recipe }) => {
  const { title, featuredImage, heroImage, meta, authors, recipeAuthors } =
    recipe;
  const href = generateRecipeUrl(recipe);
  const image = featuredImage || heroImage;

  return (
    <div className="Teaser RecipeTeaser">
      <Link to={href}>
        {image && image.src ? (
          <div className="RecipeTeaser__image w100 relative radius-xs overflow-hidden">
            <Img
              alt={image.alt || title}
              src={sanityImgUtil(image, 375)}
              srcSet={sanityImgSrcSetUtil(image, 375, 375)}
              sizes="(maxWidth: 768px) 375px, 375px"
              dimensions={{ width: 300, height: 209 }}
            />
          </div>
        ) : null}
        <h4 className="Teaser__title graebenbach font-300">
          {curlyQuotes(title)}
        </h4>
        <TeaserByLine
          authors={authors || meta?.authors}
          recipeAuthors={recipeAuthors || meta?.recipeAuthors}
        />
      </Link>
    </div>
  );
};
