import React from 'react';
import { BlockContent } from 'components/BlockContent';
import { AuthorLink } from 'sharedTypes';

interface Props {
  className?: string;
  authors: AuthorLink[];
  underline?: boolean;
}

const AuthorBioBlock: React.FC<Props> = ({ className, authors }) => {
  return (
    <div className="AuthorBioBlock col-12 lg:col-10 xl-wide:col-8 w100 mt6 mxauto">
      {authors.map((author) => {
        if (!author.bio) {
          return null;
        }

        return (
          <div
            key={`bio-${author.slug}`}
            className="AuthorBioBlock__container graebenbach mt1_5 text-section-details-sm font-300 color-red"
          >
            {typeof author.bio === 'string' ? (
              <p>{author.bio}</p>
            ) : (
              <BlockContent blocks={author.bio} />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default AuthorBioBlock;
