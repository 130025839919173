import Language from 'constants/Language';

/**
 * Separate a list of items into a comma-separated sentence
 *
 * @param {strings} strings - a list of strings to join
 */

const commaAnd = (strings: string[]): string => {
  if (strings.length === 1) return strings[0];
  if (strings.length === 2)
    return [strings[0], Language.t('Global.and'), strings[1]].join(' ');
  return [
    [...strings.slice(0, -1), ''].join(', '),
    Language.t('Global.and').concat(' '),
    strings[strings.length - 1],
  ].join('');
};

export default commaAnd;
