import React, { Component, RefObject, createRef } from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import { onWindowResize } from 'utils/onWindowResize';
import { Button, Img } from 'components/base';
import withBreakpoints, {
  InjectedProps as WithBreakpointsProps,
} from 'lib/withBreakpoints';
import { curlyQuotes } from 'utils/text';

import { Image } from 'sharedTypes';
import { ArticleImgCarouselImage } from 'types';

import Language from 'constants/Language';
import { BlockContent } from 'components/BlockContent';
import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';

const Flickity =
  typeof window === 'undefined' ? () => null : require('flickity');

interface PassProps {
  images?: Image[];
  className?: string;
  imageClassName?: string;
  pageDots?: boolean;
  wrapAround?: boolean;
  children?: React.ReactNode;
  imagesHasLoaded?: boolean;
  carouselIsOpen?: boolean;
  closeCarouselOverlay: () => void;
  activeImageId: string | null;
}

type Props = PassProps & WithBreakpointsProps;

interface State {
  captionHeight: number;
  carouselImages: ArticleImgCarouselImage[];
  activeIndex?: number;
  loadedImg: boolean[];
}

const defaultProps = {
  className: '',
  imageClassName: '',
  pageDots: true,
  wrapAround: false,
  imagesHasLoaded: false,
};

class ArticleImgCarousel extends Component<Props, State> {
  static defaultProps = defaultProps;

  wrapper: Element | null = null;
  flickity: typeof Flickity | null = null;

  captionContainers: { [key: string]: RefObject<HTMLDivElement> } | null = null;

  removeResizeListener = () => {};

  constructor(props: Props) {
    super(props);

    this.captionContainers =
      props.images && props.images.length
        ? props.images.reduce(
            (
              captionContainer: { [key: string]: RefObject<HTMLDivElement> },
              image: Image
            ) => {
              captionContainer[image.id] = createRef();

              return captionContainer;
            },
            {}
          )
        : null;

    this.state = {
      carouselImages: [],
      loadedImg: [],
      captionHeight: 0,
    };
  }

  imageIndexFromId = (activeImageId: string | null) => {
    const { images } = this.props;

    return images && images.findIndex((image) => image.id === activeImageId);
  };

  imageIdFromIndex = (index: number) => {
    const { images } = this.props;

    return images && images[index].id;
  };

  handleResize = () => {
    if (this.flickity) {
      this.flickity.destroy();
    }

    this.initializeCarousel();
  };

  componentDidMount() {
    this.removeResizeListener = onWindowResize(this.handleResize);
    this.findCaptionHeight();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { images, currentBreakpoint, imagesHasLoaded, carouselIsOpen } =
      this.props;

    const { loadedImg } = this.state;

    if (prevProps.currentBreakpoint !== currentBreakpoint) {
      this.handleResize();
    }

    if (!prevProps.imagesHasLoaded && imagesHasLoaded) {
      this.handleResize();
    }

    if (!prevProps.carouselIsOpen && carouselIsOpen) {
      this.handleResize();
    }

    if (
      images &&
      images.length <= loadedImg.length &&
      prevState.loadedImg.length !== loadedImg.length
    ) {
      this.handleResize();
    }
  }

  componentWillUnmount() {
    this.removeResizeListener();
  }

  initializeCarousel = () => {
    const { activeImageId } = this.props;

    if (this.wrapper) {
      this.flickity = new Flickity(this.wrapper, {
        initialIndex: this.imageIndexFromId(activeImageId),
        wrapAround: this.props.wrapAround,
        draggable: true,
        freeScroll: false,
        prevNextButtons: true,
        accessibility: true,
        pageDots: this.props.pageDots,
        arrowShape:
          'M45.897.844L.618 51.55l44.999 49.295 7.43-6.9L18.702 56.9h76.68V46.197h-76.68L53.327 7.746 45.897.844z',
        on: {
          ready: () => {
            setTimeout(() => this.flickity && this.flickity.resize(), 0);
          },
          change: (index?: number) => {
            if (index) {
              this.setState({ activeIndex: index });
            }
          },
        },
      });
    }
  };

  handleImgLoad = () => {
    this.setState((state) => ({ loadedImg: state.loadedImg.concat([true]) }));
  };

  findCaptionHeight = () => {
    const captions = this.captionContainers
      ? Object.values(this.captionContainers)
      : [];

    if (!captions || !captions.length) return;

    const tallestCaptionHeight = captions.reduce(
      (tallestCaptionHeight: number, caption: RefObject<HTMLElement>) => {
        const captionHeight = get(caption, 'current.clientHeight', 0);

        return captionHeight > tallestCaptionHeight
          ? captionHeight
          : tallestCaptionHeight;
      },
      0
    );

    this.setState({ captionHeight: tallestCaptionHeight });
  };

  render() {
    const {
      imageClassName,
      className,
      children,
      closeCarouselOverlay,
      carouselIsOpen,
      images,
    } = this.props;
    const imageMaxHeight: number =
      typeof window !== 'undefined'
        ? window.innerHeight - this.state.captionHeight - 100
        : 700;

    return (
      <div
        className={cx(
          'ArticleImgCarousel vw100 vh100 fixed t0 l0 py1_5 px3_25 z-overlay bg-color-black flex align-center justify-center bg-color-black',
          { 'ArticleImgCarousel--active': carouselIsOpen }
        )}
      >
        <div
          className={cx(
            'ArticleImgCarousel__carousel overflow-hidden',
            className
          )}
          ref={(node) => (this.wrapper = node)}
        >
          {images &&
            images.map((image: Image) => (
              <div
                key={image.src}
                className="ArticleImgCarousel__carousel__cell w100 vh90 flex items-center justify-center"
              >
                <div className="ArticleImgCarousel__carousel__content-wrapper">
                  <Img
                    key={image.src}
                    className={cx(
                      'ArticleImgCarousel__carousel__image mb1_5',
                      imageClassName
                    )}
                    style={{
                      maxHeight: imageMaxHeight,
                    }}
                    sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px, 1080px"
                    src={sanityImgUtil(image, 1080)}
                    srcSet={sanityImgSrcSetUtil(image, 768, 1080, 1080)}
                    alt={image.alt ? image.alt : get(image, 'caption', '')}
                    onImgLoad={this.handleImgLoad}
                  />
                  <div
                    ref={
                      get(
                        this,
                        `captionContainers[${image.id}]`
                      ) as RefObject<HTMLDivElement>
                    }
                    className="ArticleImgCarousel__carousel__image__caption-container"
                  >
                    {image.caption && (
                      <span className="ArticleImgCarousel__carousel__image__caption text-article-details-xs font-400 graebenbach color-gray-lighter">
                        {curlyQuotes(image.caption)}
                      </span>
                    )}
                    {image.rteCredit ? (
                      <span className="ImageCreditLink inline-block ml_5 color-gray text-article-details-xxs font-400 uppercase graebenbach letter-spacing-md transition-shorter">
                        <BlockContent blocks={image.rteCredit} />
                      </span>
                    ) : image.credit ? (
                      <span
                        className={cx(
                          'ArticleImgCarousel__carousel__image__credit ml_5 color-gray text-article-details-xxs font-400 uppercase graebenbach'
                        )}
                      >
                        {curlyQuotes(image.credit)}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            ))}
          {children && children}
        </div>
        <Button
          onClick={closeCarouselOverlay}
          ariaLabel={Language.t('Global.closeCarouselOverlay')}
          className="ArticleImgCarousel__close-button absolute t0 r0 mt1_5 mr3_25 bg-color-transparent white"
        >
          <Img
            alt={Language.t('Global.closeCarouselOverlay')}
            src="/assets/images/close-icon-white.svg"
            className="w100"
          />
        </Button>
      </div>
    );
  }
}

export default withBreakpoints<Props>(ArticleImgCarousel);
